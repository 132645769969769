import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import CommonUtil from "../../util/commonUtil";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { SiGooglenearby } from 'react-icons/si';
import { AiOutlineSearch } from 'react-icons/ai'
import { AiOutlinePhone } from 'react-icons/ai'
import Modal from "../modal/modal";
import { FnFriendType } from "../../lib/constants";
import PhoneInput from 'react-phone-number-input'
import { useForm } from "react-hook-form";
import DataUtils from "../../api/dataUtils"
import { socketSend } from "../../api/socket";
import SocketActions from '../../lib/socketActions';
import getLocation from "../../lib/location"

export const Friends = ({
    props }) => {

    const [fnFriends, setFnFriends] = useState([]); //popup users
    const [isShowFind, setIsShowFind] = useState(false);
    const [findType, setFindType] = useState("");
    const [phone, setPhone] = useState(false);
    const {
        register,
        handleSubmit,
    } = useForm();

    const fetchFriends = async () => {
        const friends = await DataUtils.getFriendList();
        props.setFriends(friends);
    };

    useEffect(() => {
        fetchFriends();
        // eslint-disable-next-line
    }, []);

    const removeFriendClickHandler = async (friendId) => {

        await DataUtils.removeFriend(friendId);
        await fetchFriends();
    };
    const findFriends = async (type, data) => {
        let friends = [];
        switch (type) {
            case FnFriendType.PHONE:
                if (phone === undefined) {
                    return
                }
                friends = await DataUtils.findFriendsByPhone(phone);
                break;
            case FnFriendType.NEARBY:
                // await handleLocationClick();
                const position = await getLocation();
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                const mes = await DataUtils.editLocation(longitude, latitude);
                if (mes) {
                    friends = await DataUtils.findFriendsNearBy(data);
                }
                break;
            default:
                break;
        }
        setFnFriends(friends);
    };

    const onSubmitFriendNearBy = async (data) => {
        findFriends(FnFriendType.NEARBY, data);
        setIsShowFind(true);
    }
    const addFriendClickHandler = async (friendId) => {
        await DataUtils.addFriend(friendId);
        setIsShowFind(false);
    };
    const showFriendModal = async (friendId) => {
        const profile = await DataUtils.getProfile(friendId);
        props.setFriendFrofile(profile);
        props.setIsShowFriend(true);
    };
    const getChatFriendsWithOnlineUser = () => {
        let updatedChatList = props.friends.map((user) => {
            if (props.onlineUserList.includes(user.id)) {
                user.isOnline = true;
            } else {
                user.isOnline = false;
            }
            return user;
        });

        return (updatedChatList?.map((friend) => (<div key={friend.id}
            className="card border-0 text-reset"
            style={{ textDecoration: 'none' }}>
            <div className="card-body">
                <div className="row gx-5">
                    <div className="col-auto">
                        <div onClick={() => showFriendModal(friend.id)}
                            className={"avatar " + (friend.isOnline ? "avatar-online" : "")}>
                            <img
                                src={friend.get_avatar}
                                alt="#"
                                className="avatar-img" />
                        </div>
                    </div>

                    <div className="col">
                        <div className="d-flex align-items-center mb-3">
                            <h5 className="me-auto mb-0">{friend.name}</h5>
                            <Dropdown className="text-muted extra-small ms-2">
                                <Dropdown.Toggle variant="link" bsPrefix="p-0">
                                    <BiDotsVerticalRounded color="#95aac9" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => addFriendToChatClickHandler(friend)}>New Message</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item className="text-danger" onClick={() => removeFriendClickHandler(friend.id)}>Remove</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="d-flex align-items-center">
                            <div className="line-clamp me-auto">
                                {friend.isOnline ? (
                                    <>
                                        online
                                    </>
                                ) : (
                                    <>
                                        offline
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- .card-body --> */}
        </div>
        ))
        );
    };

    const addFriendToChatClickHandler = async (friend) => {
        const user = props.rooms.find((user) => user.id === friend.id);
        if (user !== undefined) {
            props.setCurrentChattingMember(user);
            props.setCurrentRoom(user.roomId);
            props.setChatVisible(true);
        }
        else {
            const data = await DataUtils.createRoom(friend.id);
            if (data) {
                props.setCurrentRoom(data.roomId);
                props.setCurrentChattingMember(friend);
                props.setChatVisible(true);
                socketSend({ action: SocketActions.PING, roomId: data.roomId });
            }

        }
    };

    const findClick = (type) => {
        setFindType(type);
        setFnFriends([]);
        setIsShowFind(true);
    }

    const getTypeOfFind = (type) => {
        switch (type) {
            case FnFriendType.PHONE:
                return (
                    <div className='modal-py'>
                        <div className="d-flex align-items-start pt-1 pb-1 d-flex align-items-center"
                        >
                            <PhoneInput
                                // className="flex-grow-1 ml-2 mr-3"
                                className={"input-phone-number"}
                                defaultCountry="VN"
                                placeholder="Phone Number"
                                name="phone"
                                value={phone}
                                onChange={setPhone}
                            />
                            <button
                                onClick={() => findFriends(type)}
                                className="btn btn-lg btn-soft-primary ml-2"
                            >
                                Find
                            </button>

                        </div>
                    </div>
                );
            case FnFriendType.NEARBY:
                return (<form onSubmit={handleSubmit(onSubmitFriendNearBy)}>
                    <div className="authFieldContainer">
                        Distance:
                        <input
                            className="authField"
                            type="number"
                            // placeholder="Distance"
                            style={{
                                backgroundColor: '#ebf1f7',
                                color: '#a0b3ce',
                                border: "none"
                            }}
                            {...register("distance", { required: false })}
                        />
                    </div>
                    <div className="authFieldContainer">
                        Sex:
                        <select className="authField"
                            defaultValue="all"
                            style={{
                                backgroundColor: '#ebf1f7',
                                color: '#a0b3ce',
                                border: "none"
                            }}
                            {...register("sex", { required: false })}>
                            <option value="all">All</option>
                            <option value="female">Female</option>
                            <option value="male">Male</option>
                        </select>
                    </div>
                    <button className="btn btn-lg btn-soft-primary mb-2" type="submit">
                        Find
                    </button>
                    <br />
                </form>);
            default:
                break;
        }
    }
    const getMessageFind = (type) => {
        switch (type) {
            case FnFriendType.PHONE:
                return (
                    <div className='modal-py text-center'>
                        Phone number is not registered
                    </div>
                );
            case FnFriendType.NEARBY:
                return (
                    <div className='modal-py text-center'>
                        No People Found
                    </div>
                );
            default:
                break;
        }
    }

    return (
        <div className="tab-pane fade h-100 active show" id="tab-content-chats" role="tabpanel">
            <div className="d-flex flex-column h-100 position-relative">
                <div className="hide-scrollbar">
                    <div className="container py-8">
                        {/* <!-- Title --> */}
                        <div className="mb-8">
                            <h2 className="fw-bold m-0">Friends</h2>
                        </div>

                        {/* <!-- Search --> */}
                        <div className="mb-6">
                            <form action="#">
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <div className="icon icon-lg">
                                            <AiOutlineSearch />
                                        </div>
                                    </div>

                                    <input type="text" className="form-control form-control-lg ps-0" placeholder="Search messages or users" aria-label="Search for messages or users..." />
                                </div>
                            </form>
                            <div className="mt-5">
                                <div className="w-100 d-flex align-items-center" >
                                    Find Friends

                                    <button className="btn btn-light icon ms-auto"
                                        title="By Phone"
                                        onClick={() => findClick(FnFriendType.PHONE)}
                                    >
                                        <AiOutlinePhone /></button>
                                    <button className="btn btn-light icon ml-2"
                                        title="By Nearby"
                                        onClick={() => findClick(FnFriendType.NEARBY)}
                                    >
                                        <SiGooglenearby /></button>
                                </div>
                            </div>

                        </div>

                        {/* <!-- Chats --> */}
                        <div className="card-list">
                            {getChatFriendsWithOnlineUser()}
                        </div>
                        {/* <!-- Chats --> */}
                    </div>
                </div>
            </div>
            <Modal
                modalCloseHandler={() => setIsShowFind(false)}
                show={isShowFind}
            // toggle={true}
            // hide={hideModal}
            >
                <hr className="hr-bold modal-gx-n my-0" />
                {getTypeOfFind(findType)}
                <hr className="hr-bold modal-gx-n my-0" />
                {fnFriends.length > 0 ? (
                    fnFriends?.map((fnFriend) => (
                        <div
                            key={fnFriend.id}
                            // className="modal-py"
                        >
                            <div className="d-flex align-items-start pt-1 pb-1 d-flex align-items-center"
                            >
                                <img
                                    src={fnFriend.get_avatar}
                                    className="rounded-circle mr-1"
                                    alt={fnFriend.name}
                                    width="40"
                                    height="40"
                                />
                                <div className="flex-grow-1 ml-2 mr-5">
                                    {fnFriend.name}
                                </div>
                                {fnFriend.id !== CommonUtil.getUserId() &&
                                    <div>
                                        {/* {fnFriend?.get_distance} */}
                                        {CommonUtil.getDistance(fnFriend?.get_distance)}
                                        <button
                                            onClick={() => addFriendClickHandler(fnFriend.id)}
                                            className="btn btn-lg btn-soft-primary ml-2"
                                        >
                                            Add
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    ))
                ) : (

                    <div>
                        {getMessageFind(findType)}
                    </div>
                )}
            </Modal>
        </div>
    );
};
