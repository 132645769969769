import { useEffect } from "react";
import "./sidebar.css";
import CommonUtil from "../../util/commonUtil";
import DataUtils from "../../api/dataUtils";


const User = (props) => {

    const fetchProfile = async (userId) => {
        const user = await DataUtils.getProfile(userId);
        if (user) {
            props.setProfile(user);
        }
    };

    const fetchNotifications = async () => {
        const notification = await DataUtils.getNotification();
        props.setNotification(notification);
        let count = notification?.filter(x => x.is_read === false).length;
        props.setUnreadNoti(count);
    };

    useEffect(() => {
        fetchProfile(CommonUtil.getUserId());
        fetchNotifications();
        // eslint-disable-next-line
    }, []);

    return null;
};

export default User;
