const AppPaths = {
  HOME: "/",
  CHAT_ROOM: "/c/:chatId",
  LOGIN: "/login",
  SIGN_UP: "/signup",
  VERIFICATION: "/verification",
  RESET_PASSWORD: "/reset"
};

export default AppPaths;
