import React from "react";
import "./chatStyle.css";
import { IoIosArrowBack } from "react-icons/io"

export const Header = ({ currentChattingMember, setChatVisible }) => {

  const backToMain = () => {
    setChatVisible(false);
  }

  return (
    <div className="chat-header border-bottom py-4 py-lg-7">
      <div className="row align-items-center">
        <div className="col-2 d-xl-none">
          <button className="btn border-0" onClick={backToMain}>
            <IoIosArrowBack />
          </button>
        </div>
        <div className="col-8 col-xl-12">
          <div className="row align-items-center text-center text-xl-start">
            <div className="col-12 col-xl-6">
              <div className="row align-items-center gx-5">
                <div className="col-auto">
                  <div className="avatar d-none d-xl-inline-block">
                    <img className="avatar-img"
                      src={currentChattingMember?.get_avatar}
                      // className="rounded-circle mr-1"
                      alt="User"
                      width="40"
                      height="40"
                    />
                  </div>
                </div>
                <div className="col overflow-hidden">
                  <h5 className="text-truncate">
                    {currentChattingMember?.name}
                  </h5>
                  <p className="text-truncate">
                    {/* 25 member */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 d-none d-xl-block">
          </div>
        </div>
        <div className="col-2 d-xl-none text-end"></div>
      </div>
    </div>
  );
};
