import React, { useRef } from "react";
import "./modal.css";
import { IoIosArrowBack } from "react-icons/io"

const Modal = ({ modalCloseHandler, show, children }) => {
  const modalBodyRef = useRef();

  const modalOutsideClickHandler = (event) => {
    if (!modalBodyRef.current.contains(event.target)) {
      modalCloseHandler();
    }
  };

  return (
    <div
      onClick={modalOutsideClickHandler}
      className="modal"
      style={show ? { display: "block" } : { display: "none" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-fullscreen-xl-down" ref={modalBodyRef}>
        <section className="modal-content">
          <div className="text-left">
            <button
              className="modal-close btn border-0"
              onClick={modalCloseHandler}
              aria-hidden="true"
            >
              <IoIosArrowBack />
            </button>
          </div>

          <div className="modal-body py-0">{children}</div>
        </section>
      </div>
    </div>
  );
};

export default Modal;
