import React from "react";
import Modal from "./modal"
import { LiaBirthdayCakeSolid } from "react-icons/lia"
import { BsGenderFemale } from "react-icons/bs"
import { BsGenderMale } from "react-icons/bs"
import { BiPhoneCall } from "react-icons/bi"
const FriendModal = ({ isShowFriend, setIsShowFriend, friendFrofile }) => {
  return (
    <Modal
      modalCloseHandler={() => setIsShowFriend(false)}
      show={isShowFriend}
    >
      {/* <!-- Header --> */}
      <div className="profile modal-gx-n">
        <div className="profile-img text-primary">
          <img className=""
            src="https://picsum.photos/800/250"
            width="100%"
            // height="250"
            style={{ objectFit: "cover" }}
            alt="#" />
        </div>

        <div className="profile-body">
          <div className="avatar avatar-xl">
            <img className="avatar-img" src={friendFrofile.get_avatar} alt="#" />
          </div>

          <h4 className="mb-1">{friendFrofile.name}</h4>
          <p>{friendFrofile.bio}</p>
        </div>
      </div>
      {/* <!-- Header --> */}

      <hr className="hr-bold modal-gx-n my-0" />

      {/* <!-- List --> */}
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div className="row align-items-center gx-6">
            <div className="col">
              <h5>Birthday</h5>
              <p>{friendFrofile.birthday}</p>
            </div>

            <div className="col-auto">
              <div className="btn btn-sm btn-icon btn-dark">
                <LiaBirthdayCakeSolid />
              </div>
            </div>
          </div>
        </li>

        <li className="list-group-item">
          <div className="row align-items-center gx-6">
            <div className="col">
              <h5>Phone</h5>
              <p>{friendFrofile.phone}</p>
            </div>

            <div className="col-auto">
              <div className="btn btn-sm btn-icon btn-dark">
                <BiPhoneCall />
              </div>
            </div>
          </div>
        </li>

        <li className="list-group-item">
          <div className="row align-items-center gx-6">
            <div className="col">
              <h5>Sex</h5>
              <p>{friendFrofile.sex}</p>
            </div>

            <div className="col-auto">
              <div className="btn btn-sm btn-icon btn-dark">
                {friendFrofile.sex === "female" ? (<BsGenderFemale />) : (<BsGenderMale />)}
              </div>
            </div>
          </div>
        </li>

      </ul>
      {/* <!-- List  --> */}

      <hr className="hr-bold modal-gx-n my-0" />

      {/* <!-- List --> */}
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div className="text-danger">Block User</div>
        </li>
      </ul>
      {/* <!-- List --> */}
    </Modal>
  );
};

export default FriendModal;
