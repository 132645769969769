// import { getMessagingToken, onMessageListener } from './messageFirebase';
import { messaging } from '../../lib/firebase';
import { useEffect } from "react";
import CommonUtil from "../../util/commonUtil";
import ApiConnector from "../../api/apiConnector";
import ApiEndpoints from "../../api/apiEndpoints";
import { Constants } from "../../lib/constants";
import { socketSend } from "../../api/socket"
import SocketActions from '../../lib/socketActions';

const FireBaseNotification = (props) => {
    const getMessagingToken = async () => {
        let currentToken = "";

        if (!messaging) return;

        try {
            currentToken = await messaging.getToken({
                vapidKey: "BBVDvg42zOGoL3zCJ_hGJSiIeLlPpO0cfAOeZ1-eHJlyxDq-QeJv3YU23C5cR5LF-LpXEeCPIaCbsGQF1t3nMd8",
            });
        } catch (error) {
        }
        sendDeviceToken(currentToken);
    };
    const sendDeviceToken = async (token) => {
        const formData = new FormData();
        formData.append("device_token", token);
        const url = ApiEndpoints.USER_URL.replace(
            Constants.ID_PLACE_HOLDER,
            CommonUtil.getUserId()
        );
        const result = await ApiConnector.sendPutRequest(
            url,
            formData,
            true,
            true
        );
        if (result) {
        }
    };
    useEffect(() => {
        getMessagingToken();
        // eslint-disable-next-line
    }, [])
    const convertData = (data) => {

        const body = JSON.parse(data["body"]);
        data["body"] = body;
        data["is_read"] = (data["is_read"] === "false" ? false : true);
        return data;
    }
    messaging.onMessage((payload) => {
        if (payload["data"]["type_of_notification"] === "create_room") {
            socketSend({ action: SocketActions.PING, roomId: payload["data"]["roomId"] });
        }
        else if (payload["data"]["type_of_notification"] === "delete_room") {
            props.setChatVisible(false);
            //need improve
            props.setRooms((prevState) => {
                let roomState = JSON.parse(JSON.stringify(prevState));
                const newRooms = roomState.filter( obj => obj.roomId !== payload["data"]["roomId"]);
                return newRooms;
            })
            
        }
        else {
            let data = convertData(payload["data"]);
            props.setNotification((prevState) => {
                let notificationState = JSON.parse(JSON.stringify(prevState));
                notificationState.unshift(data);
                return notificationState;
            });
            props.setUnreadNoti((prevState) => {
                let unreadNoti = JSON.parse(JSON.stringify(prevState));
                unreadNoti = unreadNoti + 1;
                return unreadNoti;
            });
        }

    });
    return null;
}

export default FireBaseNotification;