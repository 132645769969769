// login.js
import React, { useState, useEffect } from "react";
import { firebase, auth } from "../../../lib/firebase";

import ApiConnector from "../../../api/apiConnector";
import ApiEndpoints from "../../../api/apiEndpoints";
import AppPaths from "../../../lib/appPaths";

const VerifyScreen = ({ location, history }) => {
  // Inputs
  // const { state } = this.props.location
  const [otp, setotp] = useState("");
  const [wrongCode, setWrongCode] = useState(false);
  const [final, setfinal] = useState("");

  // Sent OTP
  const signin = () => {
    // let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    let verify = new firebase.auth.RecaptchaVerifier("sign-in-button", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ValidateOtp();
      },
    });
    auth
      .signInWithPhoneNumber(location.state.phone, verify)
      .then((result) => {
        setfinal(result);
      })
      .catch((err) => {
        alert(err);
        window.location.reload();
      });
  };

  useEffect(() => {
    signin();
    // eslint-disable-next-line
  }, []);

  // Validate OTP
  const ValidateOtp = () => {
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then(async (result) => {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            user.getIdToken().then(async function (token) {
              // <------ Check this line
              setWrongCode(false);
              if (
                location &&
                location.state &&
                location.state.redirectFrom === AppPaths.RESET_PASSWORD
              ) {
                const formData = new FormData();
                formData.append("token", token);
                Object.keys(location.state.data).forEach((key) => {
                  formData.append(key, location.state.data[key]);
                });

                const message = await ApiConnector.sendPostRequest(
                  ApiEndpoints.RESET_PASSWORD_URL,
                  formData,
                  false,
                  true,
                  false
                );
                if (message.message === "successful") {
                  history.push({
                    pathname: AppPaths.LOGIN,
                    state: { redirectFrom: AppPaths.RESET_PASSWORD },
                  });
                }
              } else {
                const formData = new FormData();
                formData.append("token", token);
                formData.append("phone", location.state.phone);

                const message = await ApiConnector.sendPostRequest(
                  ApiEndpoints.ACTIVATE,
                  formData,
                  false,
                  true,
                  false
                );
                if (message) {
                  history.push({
                    pathname: AppPaths.LOGIN,
                    state: { redirectFrom: AppPaths.SIGN_UP },
                  });
                }
              }
            });
          }
        });
      })
      .catch((err) => {
        // alert("Wrong code");
        setWrongCode(true);
      });
  };

  return (
    <div className="bg-light">
      <div className="container">
        <div className="row align-items-center justify-content-center min-vh-100 gx-0">
          <div className="col-12 col-md-5 col-lg-4">
            <div className="card card-shadow border-0">
              <div className="card-body">
                <div className="row g-6">
                  <div className="col-12">
                    <div className="text-center">
                      <h3 className="fw-bold mb-2">Verification</h3>
                      <p>Follow the easy steps</p>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        type="text"
                        id="verification-code"
                        onChange={(e) => {
                          setotp(e.target.value);
                        }}
                      ></input>
                      {wrongCode && (
                        <p className="requiredFieldError">Wrong code</p>
                      )}
                      <label htmlFor="verification-code">Enter your OTP</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <div id="recaptcha-container"></div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div id="sign-in-button"></div>
                    <button
                      className="btn btn-lg btn-primary btn-block"
                      onClick={() => ValidateOtp()}
                    >
                      Verify
                    </button>
                    {/* {errorMessage && (
										<p className="requiredFieldError">Invalid Phone or Password</p>
									)} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-8">
              <p>Don't receive an sms code? </p>
              <div type="button" onClick={() => window.location.reload()}>
                Resend
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyScreen;
