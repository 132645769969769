const ApiEndpoints = {
  SIGN_UP_URL: "api/v1/signup/",
  ACTIVATE: "api/v1/activate/",
  LOGIN_URL: "api/v1/login/",
  USER_URL: "api/v1/user/<uuid>/",
  ME_URL: "api/v1/me",
  EDIT_ME_URL: "api/v1/editprofile/",
  EDIT_PASSWORD_URL: "api/v1/editpassword/",
  RESET_PASSWORD_URL: "api/v1/resetpassword/",
  CHECK_USER_URL: "api/v1/checkuser/",
  FIND_ALL_URL: "api/v1/friends/findall/",
  FIND_FRIENDS_URL: "api/v1/friends/find/",
  FIND_FRIENDS_NEARBY_URL: "api/v1/friends/findnearby/",
  FIND_FRIEND_BY_PHONE_URL: "api/v1/friends/findbyphone/",
  FRIENDS_URL: "api/v1/friends/<uuid>/",
  SEND_FRIENDSHIP_REQ_URL: "api/v1/friends/<uuid>/request/",
  RESP_FRIENDSHIP_REQ_URL: "api/v1/friends/<uuid>/<status>/",
  CHAT_URL: "api/v1/chats/",
  USER_CHAT_URL: "api/v1/users/<userId>/chats/",
  CHAT_MESSAGE_URL: "api/v1/chats/<roomId>/messages/",
  CHAT_ROOM_URL: "api/v1/chats/<roomId>/",
  GET_NOTIFICATION_URL: "api/v1/notification/",
  READ_NOTIFICATION_URL: "api/v1/notification/read/<uuid>/",
  DELETE_NOTIFICATION_URL: "api/v1/notification/delete/<uuid>/",
  PRESIGNED_S3_URL: "api/v1/upload/direct/geturls3/",
  PRESIGNED_S3_POST_URL: "api/v1/upload/direct/start/",
};

export default ApiEndpoints;
