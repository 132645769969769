import React, { useState } from "react";
import AuthRequired from "../../components/auth/AuthRequired";
import Chat from "../../components/chat/chat";
import Aside from "../../components/aside/aside";
import Sidebar from "../../components/sidebar/sidebar";
import FireBaseNotification from "../../components/notification/notification";
import ProfileModal from "../../components/modal/profileModal";
import FriendModal from "../../components/modal/friendModal";
import { asideName } from "../../lib/constants";
import "./HomeScreen.css";
import User from "../../components/sidebar/user";


const HomeScreen = (props) => {
  const [profile, setProfile] = useState([]);
  const [friendFrofile, setFriendFrofile] = useState([]);
  const [rooms, setRooms] = useState([]); //sidebar users
  const [currentChattingMember, setCurrentChattingMember] = useState({});
  const [currentRoom, setCurrentRoom] = useState({});
  const [friends, setFriends] = useState([]);
  const [onlineUserList, setOnlineUserList] = useState([]);
  const [roomNotify, setRoomNotify] = useState([]);
  const [aside, setAside] = useState(asideName.CHATS);
  const [chatVisible, setChatVisible] = useState(false);
  // const [chatActive, setChatActive] = useState(false);
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [isShowFriend, setIsShowFriend] = useState(false);
  const [notification, setNotification] = useState([]);
  const [unreadNoti, setUnreadNoti] = useState(0);

  return (
    // <body>
    <div className="layout overflow-hidden">
      <User
        setProfile={setProfile}
        setFriends={setFriends}
        setRooms={setRooms}
        setNotification={setNotification}
        setUnreadNoti={setUnreadNoti}
        {...props}
      />
      <Sidebar
        setAside={setAside}
        profile={profile}
        setProfile={setProfile}
        setIsShowProfile={setIsShowProfile}
        rooms={rooms}
        notification={notification}
        unreadNoti={unreadNoti}
        setUnreadNoti={setUnreadNoti}
        {...props}
      />
      <Aside
        setCurrentChattingMember={setCurrentChattingMember}
        setCurrentRoom={setCurrentRoom}
        onlineUserList={onlineUserList}
        rooms={rooms}
        setRooms={setRooms}
        roomNotify={roomNotify}
        setRoomNotify={setRoomNotify}
        aside={aside}
        setAside={setAside}
        friends={friends}
        setFriends={setFriends}
        profile={profile}
        setProfile={setProfile}
        chatVisible={chatVisible}
        setChatVisible={setChatVisible}
        setFriendFrofile={setFriendFrofile}
        setIsShowFriend={setIsShowFriend}
        setNotification={setNotification}
        setUnreadNoti={setUnreadNoti}
        notification={notification}
        {...props}
      />
      <Chat
        rooms={rooms}
        setRooms={setRooms}
        onlineUserList={onlineUserList}
        setOnlineUserList={setOnlineUserList}
        currentChattingMember={currentChattingMember}
        currentRoom={currentRoom}
        roomNotify={roomNotify}
        setRoomNotify={setRoomNotify}
        chatVisible={chatVisible}
        setChatVisible={setChatVisible}
        setIsShowProfile={setIsShowProfile}
        setFriendFrofile={setFriendFrofile}
        setIsShowFriend={setIsShowFriend}
        {...props}
      />
      <FireBaseNotification
        setRoomNotify={setRoomNotify}
        setRooms={setRooms}
        setNotification={setNotification}
        setUnreadNoti={setUnreadNoti}
        setChatVisible={setChatVisible}
        {...props}
      />
      <ProfileModal
        isShowProfile={isShowProfile}
        setIsShowProfile={setIsShowProfile}
        profile={profile}
        {...props}
      />
      <FriendModal
        isShowFriend={isShowFriend}
        setIsShowFriend={setIsShowFriend}
        friendFrofile={friendFrofile}
        {...props}
      />
    </div>
    // </body>
  );
};

export default AuthRequired(HomeScreen);
