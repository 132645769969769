import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ApiConnector from "../../../api/apiConnector";
import ApiEndpoints from "../../../api/apiEndpoints";
import AppPaths from "../../../lib/appPaths";
import "../authStyle.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const SignupScreen = ({ history }) => {
  const [phone, setPhone] = useState("");
  const [phoneCheck, setPhoneCheck] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const password1 = useRef({});
  password1.current = watch("password1");

  const onSubmit = async (signupData) => {
    const formData = new FormData();
    if (phone === undefined) {
      return;
    }
    if (isValidPhoneNumber(phone) !== true) {
      return;
    }
    formData.append("phone", phone);
    Object.keys(signupData).forEach((key) => {
      formData.append(key, signupData[key]);
    });
    const successSignupData = await ApiConnector.sendPostRequest(
      ApiEndpoints.SIGN_UP_URL,
      formData,
      false,
      true
    );
    if (successSignupData) {
      history.push({
        pathname: AppPaths.VERIFICATION,
        state: { phone: phone },
      });
    }
  };

  const checkPhoneNumber = async () => {
    setPhoneCheck(true);
  };

  return (
    <div className="bg-light">
      <div className="container">
        <div className="row align-items-center justify-content-center min-vh-100 gx-0">
          <div className="col-12 col-md-5 col-lg-4">
            <div className="card card-shadow border-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                  <div className="row g-6">
                    <div className="col-12">
                      <div className="text-center">
                        <h3 className="fw-bold mb-2">Sign Up</h3>
                        <p>Follow the easy steps</p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-floating">
                        <PhoneInput
                          id="signup-phone"
                          className={"input-phone-number"}
                          defaultCountry="VN"
                          placeholder="Phone Number"
                          name="phone"
                          value={phone}
                          onChange={setPhone}
                        />
                        {phoneCheck && (
                          <p className="requiredFieldError">
                            {phone
                              ? isValidPhoneNumber(phone)
                                ? undefined
                                : "Invalid phone number"
                              : "Phone number is required"}
                          </p>
                        )}
                        {/* <label htmlFor="signup-phone">Phone</label> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          id="signup-name"
                          className="form-control"
                          type="text"
                          placeholder="Name"
                          {...register("name", { required: true })}
                        />
                        {errors.name && (
                          <p className="requiredFieldError">Name is required</p>
                        )}
                        <label htmlFor="signup-name">Name</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          id="signup-password1"
                          className="form-control"
                          type="password"
                          placeholder="Password"
                          {...register("password1", { required: true })}
                        />
                        {errors.password1 && (
                          <p className="requiredFieldError">
                            Password is required
                          </p>
                        )}
                        <label htmlFor="signup-password1">Password</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          id="signup-password2"
                          className="form-control"
                          type="password"
                          name="password2"
                          placeholder="Confirm Password"
                          {...register("password2", {
                            required: "Password is required",
                            validate: (value) =>
                              value === password1.current ||
                              "The passwords doesn't match",
                          })}
                        />
                        {errors.password2 && (
                          <p className="requiredFieldError">
                            {errors.password2?.message}
                          </p>
                        )}
                        <label htmlFor="signup-password2">
                          Verify Password
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-lg btn-primary btn-block"
                        type="submit"
                        onClick={() => checkPhoneNumber()}
                      >
                        Create Account
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="text-center mt-8">
              <p>
                Already have an account? <Link to="/login">Sign in</Link>
              </p>
              <p>
                <Link to="/reset">Forgot Password?</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupScreen;
