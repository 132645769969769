import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ApiConnector from "../../../api/apiConnector";
import ApiEndpoints from "../../../api/apiEndpoints";
import AppPaths from "../../../lib/appPaths";
import CookieUtil from "../../../util/cookieUtil";
import "../authStyle.css";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const LoginScreen = ({ location, history }) => {
  const [phone, setPhone] = useState("");
  const [phoneCheck, setPhoneCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (loginData) => {
    if (phone === undefined) {
      return
    }
    if (isValidPhoneNumber(phone) !== true) {
      return
    }
    const formData = new FormData();
    formData.append("phone", phone);

    Object.keys(loginData).forEach((key) => {
      formData.append(key, loginData[key]);
    });

    const successLoginData = await ApiConnector.sendPostRequest(
      ApiEndpoints.LOGIN_URL,
      formData,
      false,
      true
    );
    if (successLoginData) {
      setErrorMessage(false);
      if (successLoginData.hasOwnProperty("message")) {
        history.push({
          pathname: AppPaths.VERIFICATION,
          state: { phone: phone },
        });
      }
      else {
        Object.keys(successLoginData).forEach((key) => {
          CookieUtil.setCookie(key, successLoginData[key]);
        });
        window.location.href = AppPaths.HOME;
      }
    }
    else {
      setErrorMessage(true);
    }
  };
  const checkPhoneNumber = async () => {
    setPhoneCheck(true);
  };
  const getLoginMessage = () => {
    if (
      location &&
      location.state &&
      location.state.redirectFrom === AppPaths.SIGN_UP
    ) {
      return "Your account has been created successfully. Please login."
    }
    else if (
      location &&
      location.state &&
      location.state.redirectFrom === AppPaths.RESET_PASSWORD
    ) {
      return "Your password has been reset successfully. Please login."
    }
    else {
      return "Login to your account";
    }

  };

  return (
    <div className="bg-light">

      <div className="container">
        <div className="row align-items-center justify-content-center min-vh-100 gx-0">

          <div className="col-12 col-md-5 col-lg-4">
            <div className="card card-shadow border-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                  <div className="row g-6">
                    <div className="col-12">
                      <div className="text-center">
                        <h3 className="fw-bold mb-2">Sign In</h3>
                        <p>{getLoginMessage()}</p>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-floating">
                        <PhoneInput
                          className="input-phone-number"
                          defaultCountry="VN"
                          placeholder="Phone Number"
                          name="phone"
                          value={phone}
                          onChange={setPhone}
                        />
                        {phoneCheck && (
                          <p className="requiredFieldError">{phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid phone number') : 'Phone number is required'}</p>
                        )}
                        {/* <label htmlFor="signin-phone">Phone</label> */}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          id="signin-password"
                          className="form-control"
                          type="password"
                          placeholder="Password"
                          {...register("password", { required: true })}
                        />
                        {errors.password && (
                          <p className="requiredFieldError">This field is required</p>
                        )}
                        <label htmlFor="signin-password">Password</label>
                      </div>
                    </div>

                    <div className="col-12">
                      <button className="btn btn-lg btn-primary btn-block" type="submit" onClick={() => checkPhoneNumber()}>Sign In</button>
                      {errorMessage && (
                        <p className="requiredFieldError">Invalid Phone or Password</p>
                      )}
                    </div>

                  </div>
                </div>
              </form>
            </div>

            <div className="text-center mt-8">
              <p>Don't have an account yet? <Link to="/signup">Sign up</Link></p>
              <p><Link to="/reset">Forgot Password?</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
