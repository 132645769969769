import ApiConnector from "./apiConnector";
import ApiEndpoints from "./apiEndpoints";
import { Constants } from "../lib/constants";
import CommonUtil from "../util/commonUtil";

const getProfile = async (userId) => {
  const url = ApiEndpoints.USER_URL.replace(
    Constants.ID_PLACE_HOLDER,
    userId
  );
  const result = await ApiConnector.sendGetRequest(url);
  return result
};

const editAvatar = async (file) => {
  const formData = new FormData();
  formData.append("avatar", file);

  const url = ApiEndpoints.USER_URL.replace(
    Constants.ID_PLACE_HOLDER,
    CommonUtil.getUserId()
  );
  const result = await ApiConnector.sendPutRequest(
    url,
    formData,
    true,
    true
  );
  return result;
}

const editProfile = async (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  const url = ApiEndpoints.USER_URL.replace(
    Constants.ID_PLACE_HOLDER,
    CommonUtil.getUserId()
  );
  const result = await ApiConnector.sendPutRequest(
    url,
    formData,
    true,
    true
  );
  return result;
};
const editPassword = async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  const result = await ApiConnector.sendPostRequest(
    ApiEndpoints.EDIT_PASSWORD_URL,
    formData,
    true,
    true,
    false
  );
  return result;
};

const editLocation = async (longitude, latitude) => {
  let formData = new FormData();
  formData.append("longitude", longitude);
  formData.append("latitude", latitude);
  const url = ApiEndpoints.USER_URL.replace(
    Constants.ID_PLACE_HOLDER,
    CommonUtil.getUserId()
  );
  const result = await ApiConnector.sendPutRequest(
    url,
    formData,
    true,
    true
  );
  return result;
}

const addFriend = async (friendId) => {
  const url = ApiEndpoints.SEND_FRIENDSHIP_REQ_URL.replace(
    Constants.ID_PLACE_HOLDER,
    friendId
  );
  const result = await ApiConnector.sendPostRequest(
    url,
    null,
    true,
    false
  );
  return result;
};

const responseFriendRequest = async (friendId, status) => {
  let url = ApiEndpoints.RESP_FRIENDSHIP_REQ_URL.replace(
    Constants.ID_PLACE_HOLDER,
    friendId
  );
  url = url.replace(
    Constants.FRIEND_STATUS_PLACE_HOLDER,
    status
  );
  const result = await ApiConnector.sendPostRequest(
    url,
    null,
    true,
    false,
    false
  );
  return result;
};

const removeFriend = async (friendId) => {
  const url = ApiEndpoints.FRIENDS_URL.replace(
    Constants.ID_PLACE_HOLDER,
    friendId
  );
  const result = await ApiConnector.sendDeleteRequest(url);
  return result;
};

const findFriendsByPhone = async (phone) => {

  let formData = new FormData();
  formData.append("phone", phone);

  const result = await ApiConnector.sendPostRequest(
    ApiEndpoints.FIND_FRIEND_BY_PHONE_URL,
    formData,
    true,
    true
  );
  return result
};

const findFriendsNearBy = async (data) => {

  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  const result = await ApiConnector.sendPostRequest(
    ApiEndpoints.FIND_FRIENDS_NEARBY_URL,
    formData,
    true,
    true
  );
  return result
};

const getFriendList = async () => {
  const url = ApiEndpoints.FRIENDS_URL.replace(
    Constants.ID_PLACE_HOLDER,
    CommonUtil.getUserId
  );
  const result = await ApiConnector.sendGetRequest(url);
  return result;
};

const getNotification = async () => {
  const result = await ApiConnector.sendGetRequest(ApiEndpoints.GET_NOTIFICATION_URL);
  return result;
};

const removeNotification = async (id) => {
  let url = ApiEndpoints.DELETE_NOTIFICATION_URL.replace(
    Constants.ID_PLACE_HOLDER,
    id
  );

  const result = await ApiConnector.sendDeleteRequest(
    url,
    false
  );
  return result;
};

const removeAllNotification = async () => {
  const result = await ApiConnector.sendDeleteRequest(
    ApiEndpoints.GET_NOTIFICATION_URL,
    false
  );
  return result;
};

const readAllNotification = async () => {
  const result = await ApiConnector.sendPutRequest(
    ApiEndpoints.GET_NOTIFICATION_URL,
    null,
    true,
    false
  );
  return result;
};

const readNotification = async (id) => {
  let url = ApiEndpoints.READ_NOTIFICATION_URL.replace(
    Constants.ID_PLACE_HOLDER,
    id
  );

  const result = await ApiConnector.sendPostRequest(
    url,
    null,
    true,
    false,
    false
  );
  return result;
};

const getRoomList = async () => {
  const url = ApiEndpoints.USER_CHAT_URL.replace(
    Constants.USER_ID_PLACE_HOLDER,
    CommonUtil.getUserId()
  );
  const result = await ApiConnector.sendGetRequest(url);
  return result;
};

const createRoom = async (friendId) => {

  let requestBody = {
    members: [friendId, CommonUtil.getUserId()],
    type: "DM",
  };
  const result = await ApiConnector.sendPostRequest(
    ApiEndpoints.CHAT_URL,
    JSON.stringify(requestBody),
    true,
    false,
    false
  );
  return result;
};

const removeRoom = async (roomId) => {
  let url = ApiEndpoints.CHAT_ROOM_URL.replace(
    Constants.ROOM_ID_PLACE_HOLDER,
    roomId
  );
  const result = await ApiConnector.sendDeleteRequest(url);
  return result;
};

const getChatMessage = async (roomId) => {
  const url =
    ApiEndpoints.CHAT_MESSAGE_URL.replace(
      Constants.ROOM_ID_PLACE_HOLDER,
      roomId
    ) + "?limit=20&offset=0";
  const chatMessages = await ApiConnector.sendGetRequest(url);
  return chatMessages;
};

const sendDeviceToken = async (token) => {
  const formData = new FormData();
  formData.append("device_token", token);
  const url = ApiEndpoints.USER_URL.replace(
    Constants.ID_PLACE_HOLDER,
    CommonUtil.getUserId()
  );
  const result = await ApiConnector.sendPutRequest(
    url,
    formData,
    true,
    true
  );
  return result;
};

const DataUtils = {
  getProfile: getProfile,
  editAvatar: editAvatar,
  editProfile: editProfile,
  editPassword: editPassword,
  editLocation: editLocation,
  addFriend: addFriend,
  responseFriendRequest: responseFriendRequest,
  removeFriend: removeFriend,
  findFriendsByPhone: findFriendsByPhone,
  findFriendsNearBy: findFriendsNearBy,
  getFriendList: getFriendList,
  getNotification: getNotification,
  removeNotification: removeNotification,
  removeAllNotification: removeAllNotification,
  readAllNotification: readAllNotification,
  readNotification: readNotification,
  getRoomList: getRoomList,
  createRoom: createRoom,
  removeRoom: removeRoom,
  getChatMessage: getChatMessage,
  sendDeviceToken: sendDeviceToken,
};

export default DataUtils;