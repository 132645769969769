import React, { useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import CommonUtil from "../../util/commonUtil";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { AiOutlineSearch } from 'react-icons/ai'
import { IoPeopleCircleOutline } from 'react-icons/io5'
import { FriendResSts } from "../../lib/constants";
import DataUtils from "../../api/dataUtils";
export const Notifications = ({ props }) => {


    const fetchNotifications = async () => {
        const notification = await DataUtils.getNotification();
        props.setNotification(notification);
        let count = notification?.filter(x => x.is_read === false).length;
        props.setUnreadNoti(count);

    };

    useEffect(() => {
        fetchNotifications();
        // eslint-disable-next-line
    }, []);

    const responseFriendRequest = async (friendId, status) => {
        await DataUtils.responseFriendRequest(friendId, status);
        fetchNotifications();
    };
    const removeNotification = async (id) => {
        await DataUtils.removeNotification(id);
        fetchNotifications();
    };
    // const removeAllNotification = async (id) => {
    //     const data = await DataUtils.removeNotification();
    //     fetchNotifications();
    // };
    const readAllNotification = async (id) => {
        await DataUtils.readAllNotification();
        fetchNotifications();
    };
    const readNotification = async (id) => {
        await DataUtils.readNotification(id);
        fetchNotifications(); //need to improve, do not request server moretime to get
    };

    return (
        <div className="tab-pane fade h-100 active show" id="tab-content-notifications" role="tabpanel">
            <div className="d-flex flex-column h-100">
                <div className="hide-scrollbar">
                    <div className="container py-8">

                        {/* <!-- Title --> */}
                        <div className="mb-8">
                            <h2 className="fw-bold m-0">Notifications</h2>
                        </div>

                        {/* <!-- Search --> */}
                        <div className="mb-6">
                            <form action="#">
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <div className="icon icon-lg">
                                            <AiOutlineSearch />
                                        </div>
                                    </div>

                                    <input type="text" className="form-control form-control-lg ps-0" placeholder="Search messages or users" aria-label="Search for messages or users..." />
                                </div>
                            </form>
                        </div>

                        {/* <!-- Today --> */}
                        <div className="card-list">
                            {/* <!-- Title --> */}
                            <div className="d-flex align-items-center my-4 px-6">
                                <small className="text-muted me-auto">Today</small>

                                <div type="button" onClick={() => readAllNotification()} className="text-muted small">Mark all as read</div>
                            </div>
                            {/* <!-- Title --> */}
                            {props.notification?.map((noti, index) => (
                                <div key={index} className="card border-0 mb-5"
                                    style={{ backgroundColor: noti.is_read === false ? "#d5e7fe" : "" }}

                                >
                                    <div className="card-body">

                                        <div className="row gx-5">
                                            <div className="col-auto">
                                                {/* <!-- Avatar --> */}
                                                <div className="avatar">
                                                    <img className="avatar-img" src={noti.body.avatar} alt="" />

                                                    <div className="badge badge-circle bg-primary border-outline position-absolute bottom-0 end-0">
                                                        <IoPeopleCircleOutline />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col">
                                                <div className="d-flex align-items-center mb-2">
                                                    <h5 className="me-auto mb-0">
                                                        <div >{noti.body.name}</div>
                                                    </h5>
                                                    <span className="extra-small text-muted ms-2">{CommonUtil.getTimeFromDate(noti.created_at)}</span>
                                                </div>

                                                <div className="d-flex">
                                                    <div className="me-auto">{noti.body.message}</div>
                                                    <Dropdown className="text-muted extra-small ms-2">
                                                        <Dropdown.Toggle variant="link" bsPrefix="p-0">
                                                            <BiDotsVerticalRounded color="#95aac9" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => readNotification(noti.id)}>Read</Dropdown.Item>
                                                            <Dropdown.Divider />
                                                            <Dropdown.Item className="text-danger" onClick={() => removeNotification(noti.id)}>Remove</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {noti.type_of_notification === "friendship_request" &&
                                        (<div className="card-footer">
                                            <div className="row gx-4">
                                                <div className="col">
                                                    <div onClick={() => responseFriendRequest(noti.created_by_id, FriendResSts.REJECTED)} className="btn btn-sm btn-soft-danger w-100">Delete</div>
                                                </div>
                                                <div className="col">
                                                    <div onClick={() => responseFriendRequest(noti.created_by_id, FriendResSts.ACCEPTED)} className="btn btn-sm btn-primary w-100">Confirm</div>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
