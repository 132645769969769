import { S3PresignedType } from "../lib/constants";
import ApiEndpoints from "./apiEndpoints";
import ApiConnector from "./apiConnector";

export const s3GetPresignedURL = async (type, key, fileName, fileType) => {
    let formData = new FormData();
    let url;
    switch (type) {
        case S3PresignedType.GET:
            formData.append('key', key);
            formData.append('method', "get_object");
            url = await ApiConnector.sendPostRequest(
                ApiEndpoints.PRESIGNED_S3_URL,
                formData,
                true,
                true,
                false
            );
            break;
        case S3PresignedType.DELETE:
            formData.append('key', key);
            formData.append('method', "delete_object");
            url = await ApiConnector.sendPostRequest(
                ApiEndpoints.PRESIGNED_S3_URL,
                formData,
                true,
                true,
                false
            );
            break;
        case S3PresignedType.POST:
            formData.append("file_name", fileName);
            formData.append("file_type", fileType);
            url = await ApiConnector.sendPostRequest(
                ApiEndpoints.PRESIGNED_S3_POST_URL,
                formData,
                true,
                true,
                false
            );
            break;
        default:
    }
    return url;
}

export const s3UploadFile = async (params, file) => {
    const formData = new FormData();
    for (const key in params?.fields) {
        formData.append(key, params.fields[key]);
    }
    formData.append('file', file);
    const dataReturn = await ApiConnector.sendPostRequest(
        params.url,
        formData,
        false,
        true,
        true
    );
    return dataReturn;
};


export const s3DeleteFile = async (fileList) => {

    for (const file of fileList) {
        const url = await s3GetPresignedURL(S3PresignedType.DELETE, file.key);
        await ApiConnector.sendDeleteRequest(url, true);
    }
}
export const s3GetKeyAfterUploadFile = async (fileList) => {
    let keyList = [];
    for (const file of fileList) {
        const params = await s3GetPresignedURL(S3PresignedType.POST, file.imageFile.name, file.imageFile.name);
        await s3UploadFile(params, file.imageFile);
        keyList.push(params.fields.key)
    }
    return keyList;

};