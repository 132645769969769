import React from "react";
import "./sidebar.css";
import { asideName } from "../../lib/constants";

// icons
import { GoPeople } from 'react-icons/go';
import { BsChatLeft } from 'react-icons/bs';
import { IoNotificationsOutline } from 'react-icons/io5';
import { AiOutlineSetting } from 'react-icons/ai';
import { AiOutlineClose } from 'react-icons/ai';



const Sidebar = (props) => {

  const changeAsideClickHandler = (name) => {
    props.setAside(name);
  }
  const sumUnreadRooms = props.rooms.reduce((accumulator, object) => {
    return accumulator + object.unread;
  }, 0);

  return (
    <nav className="navigation d-flex flex-column text-center navbar navbar-light hide-scrollbar">
      {/* <!-- Brand --> */}
      <a href="/" title="Messenger" className="d-none d-xl-block mb-6">
        <img
          // src={process.env.PUBLIC_URL + "/tqd-logo.png"}
          src="/logo512.png"
          className="rounded-circle mr-1"
          alt=""
          width="40"
          height="40"
        />

      </a>

      {/* <!-- Nav items --> */}
      <ul className="d-flex nav navbar-nav flex-row flex-xl-column flex-grow-1 justify-content-between justify-content-xl-center align-items-center w-100 py-4 py-lg-2 px-lg-3" role="tablist">
        {/* <!-- Invisible item to center nav vertically --> */}
        <li className="nav-item d-none d-xl-block invisible flex-xl-grow-1 active">
          <div className="nav-link py-0 py-lg-8" title="">
            <div className="icon icon-xl">
              <AiOutlineClose />
            </div>
          </div>
        </li>

        {/* <!-- Friends --> */}
        <li className="nav-item">
          <div className="nav-link py-0 py-lg-8 " id="tab-friends" onClick={() => changeAsideClickHandler(asideName.FRIENDS)} title="Friends" data-bs-toggle="tab" role="tab" aria-selected="false">
            <div className="icon icon-xl">
              <GoPeople />
            </div>
          </div>
        </li>

        {/* <!-- Chats --> */}
        <li className="nav-item">
          <div className="nav-link py-0 py-lg-8" id="tab-chats" onClick={() => changeAsideClickHandler(asideName.CHATS)} title="Chats" data-bs-toggle="tab" role="tab" aria-selected="false">
            <div className={"icon icon-xl " + (sumUnreadRooms > 0 ? "icon-badged" : "")}>
              <BsChatLeft />
              {sumUnreadRooms > 0 &&
                (<div className="badge badge-circle bg-primary">
                  <span>{sumUnreadRooms}</span>
                </div>)}
            </div>
          </div>
        </li>

        {/* <!-- Notification --> */}
        <li className="nav-item d-xl-block flex-xl-grow-1">
          <div className="nav-link py-0 py-lg-8" id="tab-notifications" onClick={() => changeAsideClickHandler(asideName.NOTIFICATIONS)} title="Notifications" data-bs-toggle="tab" role="tab" aria-selected="false">
            <div className={"icon icon-xl " + (props.unreadNoti > 0 ? "icon-badged" : "")}>
              <IoNotificationsOutline />
              {
                props.unreadNoti > 0 &&
                (<div className="badge badge-circle bg-primary">
                  <span>{props.unreadNoti}</span>
                </div>)
              }

            </div>
          </div>
        </li>

        {/* <!-- Settings --> */}
        <li className="nav-item">
          <div className="nav-link py-0 py-lg-8" id="tab-settings" onClick={() => changeAsideClickHandler(asideName.SETTINGS)} title="Settings" data-bs-toggle="tab" role="tab" aria-selected="false">
            <div className="icon icon-xl">
              <AiOutlineSetting />
            </div>
          </div>
        </li>
        {/* <!-- Profile --> */}
        <li className="nav-item d-none d-xl-block">
          <div onClick={() => props.setIsShowProfile(true)} className="nav-link p-0 mt-lg-2" data-bs-toggle="modal" data-bs-target="#modal-profile">
            <div className="avatar avatar-online mx-auto">
              <img className="avatar-img" src={props.profile.get_avatar} alt="" />
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
