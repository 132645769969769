import React, { useState, useRef } from "react";
import SocketActions from "../../lib/socketActions";
import { Constants } from "../../lib/constants";
import CommonUtil from "../../util/commonUtil";
import "./chatStyle.css";
import EmojiPicker from "emoji-picker-react";
import { s3GetKeyAfterUploadFile } from "../../api/s3Methods";
import { socketSend } from "../../api/socket";
import { BsEmojiSmile } from "react-icons/bs"
import { BsSend } from "react-icons/bs"
import { CiImageOn } from "react-icons/ci"

let isTypingSignalSent = false;
let typingTimer = 0;

export const Footer = ({ offlineList, currentRoom, setRooms }) => {

  const inputRef = useRef(null);
  const [inputMessage, setInputMessage] = useState("");
  const [imageDataURL, setImageDataURL] = useState([]);

  const handleClick = () => {
    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };

  const uploadFileHandler = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    // reset file input
    event.target.value = null

    if (fileObj.type.indexOf('image') !== -1 && fileObj.size <= 4194304) {
      processImage(fileObj);
    }

  };

  const pasteEventHandler = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const imageFile = items[i].getAsFile();
        processImage(imageFile);
      }
    }
  };

  const processImage = (imageFile) => {
    const reader = new FileReader();
    reader.onload = (event) => {

      const formData = {
        imageFile: imageFile,
        imageName: CommonUtil.setNameFromDate(new Date()),
        srcUrl: event.target.result
      }
      setImageDataURL(current => [...current, formData]);
    };
    reader.readAsDataURL(imageFile);
  };

  const removeImageClickHandler = (imageURL) => {
    setImageDataURL(imageDataURL.filter(item => item.imageName !== imageURL.imageName));
  };


  const [emojiPicker, setEmojiPicker] = useState(false);

  const chatMessageTypingHandler = (event) => {
    if (event.keyCode !== Constants.ENTER_KEY_CODE) {
      const roomId = currentRoom;
      if (!isTypingSignalSent) {
        socketSend({
          action: SocketActions.TYPING,
          roomId: roomId,
          typing: true
        })
        isTypingSignalSent = true;
      }
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        socketSend({
          action: SocketActions.TYPING,
          roomId: roomId,
          typing: false
        })
        isTypingSignalSent = false;
      }, 3000);
    } else {
      clearTimeout(typingTimer);
      isTypingSignalSent = false;
    }
  };

  const messageSubmitHandler = async (event) => {
    event.preventDefault();

    let imageLists = [];
    if (imageDataURL.length > 0) {
      imageLists = await s3GetKeyAfterUploadFile(imageDataURL);
    }
    if (inputMessage || imageDataURL.length > 0) {
      const roomId = currentRoom;
      socketSend({
        action: SocketActions.MESSAGE,
        roomId: roomId,
        message: inputMessage,
        imageLists: imageLists,
        offlineList: offlineList
      });
    }
    setInputMessage("");
    setImageDataURL([]);
  };

  return (
    <div className="chat-footer pb-3 pb-lg-7 position-absolute bottom-0 start-0">
      {imageDataURL.length > 0 && (<div className="dz-preview bg-dark dz-preview-moved "
        id="dz-preview-row"
        data-horizontal-scroll={imageDataURL}>
        {imageDataURL?.map((imageURL) => (
          <div key={imageURL.imageName} className="theme-file-preview position-relative pt-3 px-2 pb-10 mx-2 dz-processing dz-image-preview dz-error dz-complete">
            <div className="avatar avatar-lg dropzone-image-preview">
              <img onClick={() => removeImageClickHandler(imageURL)}
                src={imageURL.srcUrl}
                className="avatar-img rounded file-title"
                alt=""
                data-dz-thumbnail="" />
            </div>
          </div>
        ))} </div>)
      }

      <div style={{
        display: "flex",
        justifyContent: "flex-end"
      }}>
        {!emojiPicker ? (
          <div />
        ) : (<EmojiPicker lazyLoadEmojis="true"
          onEmojiClick={(e) => setInputMessage((inputMessage) => inputMessage + e.emoji)}
        />
        )}
      </div>
      {/* <div className="flex-grow-0 py-3 px-4"> */}
      <form className="chat-form rounded-pill bg-dark"
        data-emoji-form={imageDataURL}
        onSubmit={messageSubmitHandler}>
        <div className="row align-items-center gx-0">
          <div className="col-auto">
            <input
              style={{ display: 'none' }}
              ref={inputRef}
              type="file"
              onChange={uploadFileHandler}
            />
            <div onClick={handleClick} className="btn btn-icon btn-link text-body rounded-circle dz-clickable" id="dz-btn">
              <CiImageOn />
            </div>
          </div>
          <div className="col">
            <div className="input-group"
            >
              <input
                onChange={(event) => setInputMessage(event.target.value)}
                onPaste={(event) => pasteEventHandler(event)}
                onKeyUp={chatMessageTypingHandler}
                value={inputMessage}
                id="chat-message-input"
                type="text"
                className="form-control"
                placeholder="Type your message"
                autoComplete="off"
              />
              <div onClick={() => setEmojiPicker((prev) => !prev)} className="input-group-text text-body pe-0" data-emoji-btn="">
                <span className="icon icon-lg">
                  <BsEmojiSmile />
                </span>
                {/* <RiEmotionHappyLine className="icon icon-lg" size={25} onClick={() => setEmojiPicker((prev) => !prev)} /> */}
              </div>

            </div>
          </div>
          <div className="col-auto">
            <button className="btn btn-icon btn-primary rounded-circle ms-5">
              <BsSend />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
