// import * as firebase from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/messaging';


const firebaseConfig = {
    apiKey: "AIzaSyAoeoWEdgcCqDbwg8_2O3XNyJmJdZUXVPU",
    authDomain: "timquanhday-dev-96b1e.firebaseapp.com",
    projectId: "timquanhday-dev-96b1e",
    storageBucket: "timquanhday-dev-96b1e.appspot.com",
    messagingSenderId: "495969874874",
    appId: "1:495969874874:web:fdb96bebdada20caa5a383",
    measurementId: "G-VN08G2M7FZ"
};

firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
const messaging = firebase.messaging();

export { messaging, auth, firebase };
