import React, { useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import CommonUtil from "../../util/commonUtil";
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { AiOutlineSearch } from 'react-icons/ai'
import DataUtils from "../../api/dataUtils";
import { socketSend } from "../../api/socket";
import SocketActions from '../../lib/socketActions';

export const Chats = ({ getActiveChatClass, props }) => {

    const fetchRooms = async () => {

        const rooms = await DataUtils.getRoomList();
        if (rooms?.length > 0) {
            const formatedChatUser = CommonUtil.getFormatedRooms(
                rooms,
                props.onlineUserList
            );
            props.setRooms(formatedChatUser);
            return formatedChatUser;
        }
        else {
            props.setRooms([]);
        }
    };

    useEffect(() => {
        fetchRooms();
        // eslint-disable-next-line
    }, []);

    const removeChatClickHandler = async (roomId) => {
        await DataUtils.removeRoom(roomId);
        props.setChatVisible(false);
        fetchRooms();
    };
    const getChatListWithOnlineUser = () => {
        let updatedChatList = props.rooms.map((user) => {
            if (props.onlineUserList.includes(user.id)) {
                user.isOnline = true;
            } else {
                user.isOnline = false;
            }
            return user;
        });
        return updatedChatList;
    };
    const reDirectToRoomChat = (chatUser) => {
        props.setChatVisible(true)
        props.setCurrentChattingMember(chatUser);
        props.setCurrentRoom(chatUser.roomId);
        props.setRoomNotify((prevList) => {
            let list = JSON.parse(JSON.stringify(prevList));
            list = list.filter(item => item !== chatUser.roomId)
            return list;
        });
        socketSend({ action: SocketActions.READ, roomId: chatUser.roomId });
    }

    return (
        <div className="tab-pane fade h-100 active show" id="tab-content-chats" role="tabpanel">
            <div className="d-flex flex-column h-100 position-relative">
                <div className="hide-scrollbar">

                    <div className="container py-8">
                        {/* <!-- Title --> */}
                        <div className="mb-8">
                            <h2 className="fw-bold m-0">Chats</h2>
                        </div>

                        {/* <!-- Search --> */}
                        <div className="mb-6">
                            <form action="#">
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <div className="icon icon-lg">
                                            <AiOutlineSearch />
                                        </div>
                                    </div>

                                    <input type="text" className="form-control form-control-lg ps-0" placeholder="Search messages or users" aria-label="Search for messages or users..." />
                                </div>
                            </form>
                        </div>

                        {/* <!-- Chats --> */}
                        <div className="card-list">
                            {getChatListWithOnlineUser()?.map((chatUser, index) => {
                                return (
                                    <div onClick={() => reDirectToRoomChat(chatUser)}
                                        key={index}
                                        className="card border-0 text-reset" style={{ textDecoration: 'none' }}>
                                        {chatUser.last_message !== undefined &&
                                            (<div className="card-body">
                                                <div className="row gx-5">
                                                    <div className="col-auto">
                                                        <div className={"avatar " + (chatUser.isOnline ? "avatar-online" : "")} >
                                                            <img src={chatUser.get_avatar} alt="#" className="avatar-img" />
                                                        </div>
                                                    </div>

                                                    <div className="col">
                                                        <div className="d-flex align-items-center mb-3">
                                                            <h5 className="me-auto mb-0">{chatUser.name}</h5>
                                                            {/* <span className="text-muted extra-small ms-2"> */}
                                                            <span className="text-muted extra-small ms-2">{CommonUtil.getTimeFromDate(chatUser.timestamp)}</span>
                                                            {chatUser.id !== CommonUtil.getUserId() &&
                                                                // <div className="flex-grow-1 ml-3">
                                                                <Dropdown className="text-muted extra-small ms-2 pl-2">
                                                                    <Dropdown.Toggle variant="link" bsPrefix="p-0">
                                                                        <BiDotsVerticalRounded color="#95aac9" />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item className="text-danger" onClick={() => removeChatClickHandler(chatUser.roomId)}>Remove</Dropdown.Item>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item href="#/action-2">Report</Dropdown.Item>
                                                                        <Dropdown.Item href="#/action-3">Block</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                // </div>
                                                            }
                                                            {/* </span> */}

                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <div className="line-clamp me-auto">
                                                                {chatUser.last_message}
                                                            </div>

                                                            {chatUser.unread > 0 &&
                                                                (<div className="badge badge-circle bg-primary ms-5">
                                                                    <span>{chatUser.unread}</span>
                                                                </div>)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                        {/* <!-- .card-body --> */}
                                    </div>
                                );
                            })}
                        </div>
                        {/* <!-- Chats --> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
