import jwt_decode from "jwt-decode";
import { Constants } from "../lib/constants";
import CookieUtil from "./cookieUtil";
import AppPaths from "../lib/appPaths";

const is_date = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    return true;
  }
  return false;
};

const setNameFromDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const miliSeconds = String(date.getMilliseconds()).padStart(2, '0');

  return `${day}${month}${year}${hours}${minutes}${seconds}${miliSeconds}`;
}

const getTimeFromDate = (date) => {
  let dateObj = is_date(date) ? date : new Date(date);
  let hour = dateObj.getHours();
  let minute = dateObj.getMinutes();
  let meridian = "am";
  if (hour > 12) {
    hour -= 12;
    meridian = "pm";
  }
  if (hour === 0) {
    hour = 12;
  }
  if (minute < 10) {
    minute = "0" + minute;
  }
  return hour + ":" + minute + " " + meridian;
};

const getDistance = (distance) => {
  distance = parseInt(distance);
  if (distance > 1000)
  {
    distance = parseInt(distance/1000) + " km";
  }
  else
  {
    distance = distance + " m";
  }
  return distance;
};

const getUserId = () => {
  let token = CookieUtil.getCookie(Constants.ACCESS_PROPERTY);
  if (token) {
    let decodedToken = jwt_decode(token);
    return decodedToken.user_id;
  }
  return "";
};

const getFormatedRooms = (rooms, onlineUserList) => {
  const userId = getUserId();
  return rooms.reduce((acumulator, item) => {
    if (item.type === "DM" || item.type === "SELF") {
      let newResult = {};
      newResult["roomId"] = item.roomId;
      let member = null;
      for (let user of item.member) {
        if (user.id !== userId || item.type === "SELF") {
          member = user;
        }
      }
      if (member) {
        newResult["name"] = member.name;
        newResult["get_avatar"] = member.get_avatar;
        newResult["id"] = member.id;
        newResult["isOnline"] = onlineUserList?.includes(member.id);
      }

      for (let unread of item.unread) {
        if (unread.id === userId) {
          newResult["unread"] = unread.count;
        }
      }
      newResult["last_message"] = item.last_message?.message;
      newResult["timestamp"] = item.last_message?.timestamp;
      acumulator.push(newResult);
      return acumulator;
    }
    return acumulator;
  }, []);
};

const getActiveChatId = (match) => {
  return match && match.params ? match.params.chatId : null;
};

const logout = async () => {
  CookieUtil.deleteCookie(Constants.ACCESS_PROPERTY);
  CookieUtil.deleteCookie(Constants.REFRESH_PROPERTY);
  window.location.href = AppPaths.LOGIN;
};

const CommonUtil = {
  getTimeFromDate: getTimeFromDate,
  getDistance: getDistance,
  getUserId: getUserId,
  getFormatedRooms: getFormatedRooms,
  getActiveChatId: getActiveChatId,
  setNameFromDate: setNameFromDate,
  logout: logout,
};

export default CommonUtil;
