import ServerUrl from "./serverUrl";
import ApiUtils from "./apiUtils";
import HttpMethods from "./httpMethods";

const sendGetRequest = (relativeUrl, isS3) => {
	let url;
	let options;
	if (!isS3) {
		url = ServerUrl.SERVER_URL + relativeUrl;
		options = { headers: ApiUtils.getAuthHeader() };
	}
	else {
		url = relativeUrl;
	}

	return fetch(url, options)
		.then(ApiUtils.statusHandler)
		.then(ApiUtils.jsonHandler)
		.then((data) => data)
		.catch((error) => false);
};

const sendDeleteRequest = (relativeUrl, isS3) => {
	let url;
	let options = {
		method: HttpMethods.DELETE,
	};
	if (!isS3) {
		url = ServerUrl.SERVER_URL + relativeUrl;
	}
	else {
		url = relativeUrl;
	}
	if (!isS3) {
		options.headers = ApiUtils.getAuthHeader();
	}
	return fetch(url, options)
		.then(ApiUtils.statusHandler)
		.then(ApiUtils.jsonHandler)
		.then((data) => data)
		.catch((error) => false);
};

const sendPostRequest = (relativeUrl, requestBody, isAuth, isFormData, isS3) => {
	let url;
	if (!isS3) {
		url = ServerUrl.SERVER_URL + relativeUrl;
	}
	else {
		url = relativeUrl;
	}
	let options = {
		method: HttpMethods.POST,
		body: requestBody,
	};
	if (!isFormData) {
		options.headers = ApiUtils.getRequestHeader();
	}
	if (isAuth) {
		if (!isFormData) {
			options.headers = {
				...options.headers,
				...ApiUtils.getAuthHeader(),
			};
		} else {
			options.headers = ApiUtils.getAuthHeader();
		}
	}
	return fetch(url, options)
		.then(ApiUtils.statusHandler)
		.then(ApiUtils.jsonHandler)
		.then((data) => data)
		.catch((error) => false);
};

const sendPutRequest = (relativeUrl, requestBody, isAuth, isFormData) => {
	const url = ServerUrl.SERVER_URL + relativeUrl;
	let options = {
		method: HttpMethods.PUT,
		body: requestBody,
	};
	if (!isFormData) {
		options.headers = ApiUtils.getRequestHeader();
	}
	if (isAuth) {
		if (!isFormData) {
			options.headers = {
				...options.headers,
				...ApiUtils.getAuthHeader(),
			};
		} else {
			options.headers = ApiUtils.getAuthHeader();
		}
	}
	return fetch(url, options)
		.then(ApiUtils.statusHandler)
		.then(ApiUtils.jsonHandler)
		.then((data) => data)
		.catch((error) => false);
};

const ApiConnector = {
	sendGetRequest: sendGetRequest,
	sendPostRequest: sendPostRequest,
	sendPutRequest: sendPutRequest,
	sendDeleteRequest: sendDeleteRequest,
};

export default ApiConnector;
