import React, { useEffect, useState } from "react";
import { s3GetPresignedURL } from "../../api/s3Methods";
import { S3PresignedType } from "../../lib/constants";
import SocketActions from "../../lib/socketActions";
import CommonUtil from "../../util/commonUtil";
import "./chatStyle.css";
import { Body } from "./body";
import { Header } from "./header";
import { Footer } from "./footer";
import socket from "../../api/socket";
import { BsChatLeft } from "react-icons/bs"
import DataUtils from "../../api/dataUtils";
const Chat = ({ match,
  setRooms,
  currentChattingMember,
  currentRoom,
  setOnlineUserList,
  chatVisible,
  setChatVisible,
  setIsShowProfile,
  setFriendFrofile,
  setIsShowFriend,
  props }) => {
  const [messages, setMessages] = useState({});
  const [typing, setTyping] = useState(false);

  const convertKeytoUrl = async (message) => {
    for (let resultIndex = 0; resultIndex < message?.results?.length; resultIndex++) {
      for (let keyIndex = 0; keyIndex < message?.results[resultIndex].images?.length; keyIndex++) {
        const key = message?.results[resultIndex].images[keyIndex];
        const url = await s3GetPresignedURL(S3PresignedType.GET, message?.results[resultIndex].images[keyIndex]);
        const image = {
          "key": key,
          "url": url
        }
        message.results[resultIndex].images[keyIndex] = image;
      }
    }
    return message;
  }
  const convertKeytoUrlOnMessage = async (message) => {
    for (let keyIndex = 0; keyIndex < message.images?.length; keyIndex++) {
      const key = message.images[keyIndex];
      const url = await s3GetPresignedURL(S3PresignedType.GET, message.images[keyIndex]);
      const image = {
        "key": key,
        "url": url
      }
      message.images[keyIndex] = image;

    }
    return message;
  }

  const fetchChatMessage = async () => {
    const currentRoomId = currentRoom;
    if (currentRoomId) {
      let chatMessages = await DataUtils.getChatMessage(currentRoomId);
      chatMessages = await convertKeytoUrl(chatMessages)
      setMessages(chatMessages);
    }
  };

  useEffect(() => {
    fetchChatMessage();
    // eslint-disable-next-line
  }, [currentRoom]);

  socket.onmessage = async (event) => {
    const data = JSON.parse(event.data);
    const chatId = currentRoom;
    const userId = CommonUtil.getUserId();
    if (chatId === data.roomId) {
      if (data.action === SocketActions.MESSAGE) {
        const dataToMessage = await convertKeytoUrlOnMessage(data)
        setMessages((prevState) => {
          let messagesState = JSON.parse(JSON.stringify(prevState));
          messagesState.results.unshift(dataToMessage);
          return messagesState;
        });
        setRooms((prevState) => {
          let roomState = JSON.parse(JSON.stringify(prevState));
          let idx = roomState.findIndex((obj => obj.roomId === data.roomId));
          if (idx !== -1) {
            roomState[idx].last_message = data.message;
            roomState[idx].timestamp = data.timestamp;
          }
          return roomState;
        })
        setTyping(false);
      } else if (data.action === SocketActions.TYPING && data.user !== userId) {
        setTyping(data.typing);
      }
      else if (data.action === SocketActions.READ && data.user === userId) {
        setRooms((prevState) => {
          let roomState = JSON.parse(JSON.stringify(prevState));
          let idx = roomState.findIndex((obj => obj.roomId === data.roomId));
          if (idx !== -1) {
            roomState[idx].unread = 0;
          }
          return roomState;
        })
      }

      else if (data.action === SocketActions.REMOVE) {
        setMessages((prevState) => {
          let messagesState = JSON.parse(JSON.stringify(prevState));
          let resulttmp = messagesState.results
          resulttmp = resulttmp.filter(item => item.messageId !== data.messageId)
          messagesState.results = resulttmp
          return messagesState;
        });
        setTyping(false);
      }
    }
    else {
      setTyping(false)
      if (data.action === SocketActions.MESSAGE) {
        setRooms((prevState) => {
          let roomState = JSON.parse(JSON.stringify(prevState));
          let idx = roomState.findIndex((obj => obj.roomId === data.roomId));
          if (idx !== -1) {
            roomState[idx].unread += 1;
            roomState[idx].last_message = data.message;
            roomState[idx].timestamp = data.timestamp;
          }
          else {
            let roomData = {};
            roomData["roomId"] = data.roomId;
            roomData["get_avatar"] = data.userImage;
            roomData["id"] = data.user;
            roomData["isOnline"] = true;
            roomData["name"] = data.userName;
            roomData["unread"] = 1;
            roomState.unshift(roomData);
          }
          return roomState;
        })
      }

    }
    if (data.action === SocketActions.ONLINE_USER) {
      setOnlineUserList(data.userList);
    }
  };

  const getOfflineListInRoom = () => {
    let offlineList = [];
    return offlineList;
  }
  if (chatVisible) {
    return (
      // <main className="main is-visible" data-dropzone-area>
      <main className={"main " + (chatVisible ? 'is-visible' : '')} data-dropzone-area>
        <div className="container h-100">
          <div className="d-flex flex-column h-100 position-relative">
            <Header
              currentChattingMember={currentChattingMember}
              setChatVisible={setChatVisible}
            />
            <Body
              messages={messages}
              typing={typing}
              setIsShowProfile={setIsShowProfile}
              setFriendFrofile={setFriendFrofile}
              setIsShowFriend={setIsShowFriend}
              currentChattingMember={currentChattingMember}
            />
            <Footer
              match={match}
              setRooms={setRooms}
              currentRoom={currentRoom}
              offlineList={getOfflineListInRoom()}
              {...props}
            />
          </div>
        </div>
      </main>
    );
  }
  else {
    return (
      // <main className="main is-visible" data-dropzone-area>
      <main className={"main " + (chatVisible ? 'is-visible' : '')} data-dropzone-area>
        <div className="container h-100">
          <div className="d-flex flex-column h-100 justify-content-center text-center">
            <div className="mb-6">
              <span className="icon icon-xl text-muted">
                <BsChatLeft />
              </span>
            </div>
            <p className="text-muted">Pick a person from left menu,
              <br /> and start your conversation.</p>
          </div>
        </div>
      </main>
    );
  }

};

export default Chat;
