import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/home/HomeScreen";
import LoginScreen from "./screens/auth/login/LoginScreen";
import SignupScreen from "./screens/auth/signup/SignupScreen";
import VerifyScreen from "./screens/auth/otp/VerifyScreen";
import AppPaths from "./lib/appPaths";
import ResetPassword from "./screens/auth/reset/ResetPassword";

export default class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path={AppPaths.HOME} exact component={HomeScreen} />
          <Route path={AppPaths.LOGIN} exact component={LoginScreen} />
          <Route path={AppPaths.SIGN_UP} exact component={SignupScreen} />
          <Route path={AppPaths.RESET_PASSWORD} exact component={ResetPassword} />
          <Route path={AppPaths.VERIFICATION} exact component={VerifyScreen} />
        </Switch>
      </Router>
    );
  }
}
