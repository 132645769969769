import React from "react";
import SocketActions from "../../lib/socketActions";
import CommonUtil from "../../util/commonUtil";
import "./chatStyle.css";
import Dropdown from 'react-bootstrap/Dropdown';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { s3DeleteFile } from "../../api/s3Methods";
import socket from "../../api/socket";
import DataUtils from "../../api/dataUtils"


export const Body = ({ messages,
  typing,
  setIsShowProfile,
  setFriendFrofile,
  setIsShowFriend,
  currentChattingMember }) => {
  const loggedInUserId = CommonUtil.getUserId();

  const sendRemoveMessageSignal = async (message) => {
    // await deleteImageS3(message.images);
    await s3DeleteFile(message.images);
    socket.send(
      JSON.stringify({
        action: SocketActions.REMOVE,
        user: CommonUtil.getUserId(),
        roomId: message.roomId,
        messageId: message.messageId,
      })
    );
  };
  const getChatMessageClassName = (userId) => {
    return loggedInUserId === userId
      ? "message message-out"
      : "message";
  };

  const showProfileClickHandler = async (userId) => {
    if (userId === loggedInUserId) {
      setIsShowProfile(true);
    }
    else {
      const profile = await DataUtils.getProfile(userId);
      setFriendFrofile(profile);
      setIsShowFriend(true);
    }

  };
  const renderMessageCheck = (message, index) => {
    if (index === 0) { return true }
    else {
      if (message.user === messages?.results[index - 1].user) {
        return false;
      }
      else {
        return true;
      }
    }

  }
  return (
    <div className="chat-body hide-scrollbar flex-1 h-100">
      <div className="chat-body-inner" style={{ paddingBottom: "87px" }}
      >
        {/* <div className="py6 py-lg-12 d-flex flex-column-reverse"> */}
        <div className="py6 py-lg-12 d-flex flex-column-reverse">
          {typing && (
            <div className="ml-5">
              <div className="message-inner">
                <div className="message-body">
                  <div className="message-content">
                    <div className="message-text">
                      <p>Typing<span className="typing-dots"><span>.</span><span>.</span><span>.</span></span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {messages?.results?.map((message, index) => (
            <div key={index} className={getChatMessageClassName(message.user)}>
              <div
                data-bs-toggle="modal"
                data-bs-target="#modal-user-profile"
                className="avatar avatar-responsive">
                {renderMessageCheck(message, index) && (<img className="avatar-img" onClick={() => showProfileClickHandler(message.user)} src={message.userImage} alt="" />)}
              </div>
              <div className="message-inner">
                <div className="message-body">
                  <div className="message-content">
                    {/* <div className="message-text"> */}
                    {message.message &&
                      <div className="message-text">
                        {message.message}
                      </div>}
                    {/* </div> */}
                    <div className="message-gallery">
                      <div className="row gx-3">
                        {message.images?.length > 0 &&
                          message.images?.map((image) => (
                            <div key={image.key} className="col">
                              <img className="img-fluid rounded" src={image.url} data-action="zoom" alt="" style={{}} />
                            </div>
                          ))
                        }
                      </div>
                    </div>
                    <div className="message-action">
                      {message.user === CommonUtil.getUserId() ?
                        (<div >
                          <Dropdown>
                            <Dropdown.Toggle variant="link" bsPrefix="p-0">
                              <BiDotsVerticalRounded color="#95aac9" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => sendRemoveMessageSignal(message)}>Remove</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>) : (<div>
                          <Dropdown>
                            <Dropdown.Toggle variant="link" bsPrefix="p-0">
                              <BiDotsVerticalRounded color="#95aac9" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-3">Report</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>)
                      }
                    </div>
                  </div>
                </div>
                <div className="message-footer">
                {renderMessageCheck(message, index) && (<span className="extra-small text-muted">{CommonUtil.getTimeFromDate(message.timestamp)}</span>)}
                </div>
                {/* {renderMessageCheck(message, index) && (<span className="extra-small text-muted">{CommonUtil.getTimeFromDate(message.timestamp)}</span>)} */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
