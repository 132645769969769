const SocketActions = {
  MESSAGE: "message",
  UNREAD: "unread",
  READ: "read",
  IMAGE: "image",
  REMOVE: "remove",
  TYPING: "typing",
  PING: "ping",
  ONLINE_USER: "onlineUser",
};

export default SocketActions;
