import ServerUrl from "./serverUrl";
import SocketActions from "../lib/socketActions";
import CommonUtil from "../util/commonUtil";

let socket = new WebSocket(
  ServerUrl.WS_SERVER_URL + `ws/users/${CommonUtil.getUserId()}/chat/`
);

export const socketSend = async ({ action, roomId, message, imageLists, offlineList, typing }) => {

  switch (action) {
    case SocketActions.TYPING:
      socket.send(
        JSON.stringify({
          action: action,
          typing: typing,
          user: CommonUtil.getUserId(),
          roomId: roomId,
        })
      );
      break;
    case SocketActions.MESSAGE:
      socket.send(
        JSON.stringify({
          action: action,
          offline_list: offlineList,
          message: message,
          images: imageLists, // Todo for upload images
          user: CommonUtil.getUserId(),
          roomId: roomId,
        })
      );
      break;
    case SocketActions.PING:
      socket.send(
        JSON.stringify({
          action: SocketActions.PING,
          user: CommonUtil.getUserId(),
          roomId: roomId,
        })
      );
      break;
    case SocketActions.READ:
      socket.send(
        JSON.stringify({
          action: SocketActions.READ,
          user: CommonUtil.getUserId(),
          roomId: roomId,
        })
      );
      break;
    default:
  }
};

export default socket;