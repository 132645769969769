import React, { useRef } from "react";
import { Constants } from "../../lib/constants";
import { AiOutlineSearch } from 'react-icons/ai'
import { IoIosLogOut } from 'react-icons/io'
import Accordion from 'react-bootstrap/Accordion';
import { CiImageOn } from "react-icons/ci"
import { useForm } from "react-hook-form";
import CookieUtil from "../../util/cookieUtil";
import AppPaths from "../../lib/appPaths";
import DataUtils from "../../api/dataUtils";

export const Settings = ({ props }) => {

    const {
        register,
        handleSubmit,
        reset,
    } = useForm();

    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        reset: reset2,
        watch: watch2,
    } = useForm();

    const new_password1 = useRef({});
    new_password1.current = watch2("new_password1");

    const editAvatar = async (e) => {
        const file = e.target.files[0];
        const result = await DataUtils.editAvatar(file);
        if (result) {
            props.setProfile(result.user);
        }
    }
    const editProfile = async (data) => {
        const result = await DataUtils.editProfile(data);
        if (result) {
            props.setProfile(result.user);
            reset({
                name: "",
                bio: "",
                birthday: "",
                sex: "",
            });
        }
    };
    const editPassword = async (data) => {
        const result = await DataUtils.editPassword(data);
        if (result) {
            reset2({
                old_password: "",
                new_password1: "",
                new_password2: "",
            });
        }
    };
    const sendDeviceToken = async (token) => {
        await DataUtils.sendDeviceToken(token)
    };
    const logoutClickHandler = async () => {
        await sendDeviceToken(null);
        CookieUtil.deleteCookie(Constants.ACCESS_PROPERTY);
        CookieUtil.deleteCookie(Constants.REFRESH_PROPERTY);
        window.location.href = AppPaths.LOGIN;
    };
    return (
        <div className="tab-pane fade h-100 active show" id="tab-content-chats" role="tabpanel">
            <div className="d-flex flex-column h-100 position-relative">
                <div className="hide-scrollbar">
                    <div className="container py-8">
                        {/* <!-- Title --> */}
                        <div className="mb-8">
                            <h2 className="fw-bold m-0">Settings</h2>
                        </div>

                        {/* <!-- Search --> */}
                        <div className="mb-6">
                            <form action="#">
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <div className="icon icon-lg">
                                            <AiOutlineSearch />
                                        </div>
                                    </div>

                                    <input type="text" className="form-control form-control-lg ps-0" placeholder="Search messages or users" aria-label="Search for messages or users..." />
                                </div>
                            </form>
                        </div>

                        {/* <!-- Profile --> */}
                        <div className="card border-0">
                            <div className="card-body">
                                <div className="row align-items-center gx-5">
                                    <div className="col-auto">
                                        <div className="avatar">
                                            <img src={props.profile.get_avatar} alt="#" className="avatar-img" />

                                            <div className="badge badge-circle bg-secondary border-outline position-absolute bottom-0 end-0">
                                                <CiImageOn />
                                            </div>
                                            <input
                                                id="upload-profile-photo"
                                                className="d-none"
                                                type="file"
                                                // value={avatar}
                                                onChange={event => editAvatar(event)}
                                            />
                                            <label className="stretched-label mb-0" htmlFor="upload-profile-photo"></label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <h5>{props.profile.name}</h5>
                                        <p>{props.profile.phone}</p>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-block btn-light" onClick={() => logoutClickHandler()}>
                                            <div className="icon">
                                                <IoIosLogOut />
                                            </div>
                                        </button>
                                        {/* <button className="btn btn-block btn-light" onClick={() => logoutClickHandler()}>
                                            <IoIosLogOut />
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Profile --> */}

                        {/* <!-- Account --> */}
                        <div className="mt-8">
                            <div className="d-flex align-items-center mb-4 px-6">
                                <small className="text-muted me-auto">Account</small>
                            </div>

                            <div className="card border-0">
                                {/* <div className="card-body py-2"> */}
                                {/* <!-- Accordion --> */}
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <div>
                                                <h5>Profile settings</h5>
                                                <p>Change your profile settings</p>
                                            </div>
                                        </Accordion.Header>

                                        <Accordion.Body>
                                            <form onSubmit={handleSubmit(editProfile)}>
                                                <div className="form-floating mb-6">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        id="profile-name"
                                                        placeholder="Name"
                                                        defaultValue={props.profile.name}
                                                        {...register("name", { required: false })}
                                                    />
                                                    <label htmlFor="profile-name">Name</label>
                                                </div>
                                                <div className="form-floating mb-6">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="profile-bio"
                                                        placeholder="Bio"
                                                        defaultValue={props.profile.bio}
                                                        {...register("bio", { required: false })}
                                                    />
                                                    <label htmlFor="profile-bio">Bio</label>
                                                </div>
                                                <div className="form-floating mb-6">
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        placeholder="Birthday"
                                                        id="profile-birthday"
                                                        defaultValue={props.profile.birthday}
                                                        {...register("birthday", { required: false })}
                                                    />
                                                    <label htmlFor="profile-birthday">Birthday</label>
                                                </div>

                                                <div className="form-floating mb-6">
                                                    <select className="form-control"
                                                        id="profile-sex"
                                                        defaultValue={props.profile.sex}
                                                        {...register("sex", { required: false })}>
                                                        <option value="female">Female</option>
                                                        <option value="male">Male</option>
                                                    </select>
                                                    <label htmlFor="profile-birthday">Sex</label>
                                                </div>
                                                <button type="submit" className="btn btn-block btn-lg btn-primary w-100">Save</button>
                                            </form>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                {/* </div> */}
                            </div>
                        </div>
                        {/* <!-- Account --> */}

                        {/* <!-- Security --> */}
                        <div className="mt-8">
                            <div className="d-flex align-items-center my-4 px-6">
                                <small className="text-muted me-auto">Security</small>
                            </div>

                            <div className="card border-0">
                                {/* <div className="card-body py-2"> */}
                                {/* <!-- Accordion --> */}
                                <Accordion >
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <div>
                                                <h5>Password</h5>
                                                <p>Change your password</p>
                                            </div>
                                        </Accordion.Header>

                                        <Accordion.Body>
                                            <form onSubmit={handleSubmit2(editPassword)} >
                                                <div className="form-floating mb-6">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="profile-current-password"
                                                        placeholder="Current Password"
                                                        autoComplete=""
                                                        {...register2("old_password", { required: true })}
                                                    />
                                                    {errors2.old_password && (
                                                        <p className="requiredFieldError">Current password is required</p>
                                                    )}
                                                    <label htmlFor="profile-current-password">Current Password</label>
                                                </div>

                                                <div className="form-floating mb-6">
                                                    <input type="password"
                                                        className="form-control"
                                                        id="profile-new-password"
                                                        placeholder="New password"
                                                        autoComplete=""
                                                        {...register2("new_password1", { required: true })}
                                                    />
                                                    {errors2.new_password1 && (
                                                        <p className="requiredFieldError">New password is required</p>
                                                    )}
                                                    <label htmlFor="profile-new-password">New Password</label>
                                                </div>

                                                <div className="form-floating mb-6">
                                                    <input type="password"
                                                        className="form-control"
                                                        id="profile-verify-password"
                                                        placeholder="Verify Password"
                                                        autoComplete=""
                                                        {...register2("new_password2", {
                                                            required: "Verify password is required",
                                                            validate: (value) =>
                                                                value === new_password1.current || "The passwords doesn't match",
                                                        })}
                                                    />
                                                    {errors2.new_password2 && (
                                                        <p className="requiredFieldError">
                                                            {errors2.new_password2?.message}
                                                        </p>
                                                    )}
                                                    <label htmlFor="profile-verify-password">Verify Password</label>
                                                </div>
                                                <button type="submit" className="btn btn-block btn-lg btn-primary w-100">Save</button>
                                            </form>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                {/* </div> */}
                            </div>
                        </div>
                        {/* <!-- Security --> */}

                    </div>
                </div>
            </div>
        </div>
    );
};
