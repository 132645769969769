function getCoordinates() {
    return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
}

const getLocation = async () => {
    const position = await getCoordinates();
    return position;
}

export default getLocation;