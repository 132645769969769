const Constants = {
  ACCESS_PROPERTY: "access",
  REFRESH_PROPERTY: "refresh",
  USER_ID_PLACE_HOLDER: "<userId>",
  ID_PLACE_HOLDER: "<uuid>",
  FRIEND_STATUS_PLACE_HOLDER: "<status>",
  ROOM_ID_PLACE_HOLDER: "<roomId>",
  ENTER_KEY_CODE: 13,
};
const FriendResSts = {
  SENT: "sent",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
};

const FnFriendType = {
  PHONE: "phone",
  NEARBY: "nearby",
  ALL: "all",
};

const S3PresignedType = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

const asideName = {
  CHATS: "chat",
  FRIENDS: "friends",
  NOTIFICATIONS: "notitications",
  SETTINGS: "settings",
};

export {
  Constants, 
  FriendResSts,
  FnFriendType,
  S3PresignedType,
  asideName,
};