import React from "react";
import "./aside.css";
import { asideName } from "../../lib/constants";
import { Chats } from "./chats"
import { Friends } from "./friends"
import { Settings } from "./settings"
import { Notifications } from "./notifications"

import 'react-phone-number-input/style.css'

const Aside = (props) => {

  const getActiveChatClass = (roomId) => {
    let activeChatId = props.currentRoom;
    return roomId === activeChatId ? "active-chat" : "";
  };

  const getChatListWithOnlineUser = () => {
    let updatedChatList = props.rooms?.map((user) => {
      if (props.onlineUserList.includes(user.id)) {
        user.isOnline = true;
      } else {
        user.isOnline = false;
      }
      return user;
    });
    return updatedChatList;
  };

  const selectAside = () => {
    switch (props.aside) {
      case asideName.CHATS:
        return (<Chats
          getChatListWithOnlineUser={getChatListWithOnlineUser}
          getActiveChatClass={getActiveChatClass}
          props={props}
        />)
      case asideName.FRIENDS:
        return (<Friends
          getChatListWithOnlineUser={getChatListWithOnlineUser}
          getActiveChatClass={getActiveChatClass}
          props={props}
        />)
      case asideName.NOTIFICATIONS:
        return (<Notifications
          getChatListWithOnlineUser={getChatListWithOnlineUser}
          getActiveChatClass={getActiveChatClass}
          props={props}
        />)
      case asideName.SETTINGS:
        return (<Settings
          getChatListWithOnlineUser={getChatListWithOnlineUser}
          getActiveChatClass={getActiveChatClass}
          props={props}
        />)
      default:
        return (<div>this is differendt</div>)
    }
  }
  return (
    <aside className="sidebar bg-light">
      {selectAside()}
      <hr className="d-block d-lg-none mt-1 mb-0" />
    </aside>
  );
};

export default Aside;
